const theme = {
  colorPrim: "#00adef",
  colorSec: "#ffe05d",
  colorTert: "#01354b",
  colorAcc: "#c7c8ca",
  white: "#fff",
  grey: "#797e83",
  paraGrey: "#696969;",
  greyLight: "#eee",
  black: "#000",
  strongred: "#f00",
  maxWidth: "1000px",
  baseLineHeight: "1.5",
  bpTablet: "768px",
  bpDesksm: "1025px",
  bpDeskmd: "1200px",
  bpDesklg: "1600px",
  bpMax: "1900px",
  fontPrim: "Open Sans",
  fontSec: "Poppins",
  fontAwesome: "FontAwesome",
  yelloBoarder: "solid 1.5rem #ffe05d",
}

export default theme
